import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./Features/Auth/AuthSlice";
import feesesReducer from "./Features/Fees/FeesSlice";
import forgetPasswordsReducer from "./Features/ForgetPassword/ForgetPasswordSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        feeses: feesesReducer,
        forgetPasswords: forgetPasswordsReducer,
    },
});