import { Box, Button, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Cookies from 'js-cookie';
import FullPageLoader from '../Loaders/FullPageLoader';
import { useNavigate } from 'react-router-dom';
import { resetAuthState } from '../../Features/Auth/AuthSlice';
import { useDispatch } from 'react-redux';

const Header = ({ pageName, shouldBack = false }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [loader, setLoader] = useState(false)

    const handleLogout = () => {
        setLoader(true)
        Cookies.remove('cchsMobileLoggedInUserToken')
        dispatch(resetAuthState())
        navigate('/')
        // setTimeout(() => {
        //     window?.location?.reload()
        // }, 500);
    }

    return (
        <>
            {loader && <FullPageLoader />}
            <Paper 
                elevation={3}
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    borderRadius: 0,
                    zIndex: 1100,
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, p: 1 }}>
                        {
                            shouldBack ?
                                <Tooltip title="Back" onClick={()=>navigate(-1)}>
                                    <IconButton sx={{ p: 0 }}>
                                        <ArrowBackIcon fontSize='small' color='success' />
                                    </IconButton>
                                </Tooltip>
                            : ''
                        }
                        <Typography component="h4">{pageName}</Typography>
                    </Box>
                    <Button variant="text" onClick={handleLogout} color='success'>Sign out <PowerSettingsNewIcon fontSize='small' /></Button>
                </Box>
            </Paper>
        </>
    )
}

export default Header