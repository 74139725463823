import './App.css';
import { Route, Routes } from 'react-router-dom';
import Login from './Pages/Guest/Login';
import DashboardIndex from './Pages/Auth/Dashboard/Index';
import AddFees from './Pages/Auth/Fees/Add';
import FeesList from './Pages/Auth/Fees/List';
import FeesDetail from './Pages/Auth/Fees/Detail';
import EditFees from './Pages/Auth/Fees/Edit';
import ForgetPassword from './Pages/Guest/ForgetPassword';
import ProfileIndex from './Pages/Auth/Profile/Index';
import ChangePassword from './Pages/Auth/Profile/ChangePassword';
import MessageList from './Pages/Auth/Message/List';
import FeesListInUpdates from './Pages/Auth/Updates/List';
import FeesDetailInUpdates from './Pages/Auth/Updates/Detail';
import YearlyFeesIndex from './Pages/Auth/YearlyFees/Index';
import { ThemeProvider, createTheme } from '@mui/material/styles';



const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#2e7d32',
          },
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: '#2e7d32',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#2e7d32',
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#2e7d32',
          },
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: '#2e7d32',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#2e7d32',
            },
          },
        },
      },
    },
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/cchs-mobile/forget-password' element={<ForgetPassword />} />

          {/* Protected Routes */}
          <Route path='/cchs-mobile/home' element={<DashboardIndex />} />
          <Route path='/cchs-mobile/profile' element={<ProfileIndex />} />
          <Route path='/cchs-mobile/change-password' element={<ChangePassword />} />
          <Route path='/cchs-mobile/add-fees' element={<AddFees />} />
          <Route path='/cchs-mobile/fees-list' element={<FeesList />} />
          <Route path='/cchs-mobile/fees-detail/:id' element={<FeesDetail />} />
          <Route path='/cchs-mobile/edit-fees/:id' element={<EditFees />} />
          <Route path='/cchs-mobile/message-list' element={<MessageList />} />
          <Route path='/cchs-mobile/updates' element={<FeesListInUpdates />} />
          <Route path='/cchs-mobile/fees-detail-in-updates/:id' element={<FeesDetailInUpdates />} />
          <Route path='/cchs-mobile/yearly-fees-list' element={<YearlyFeesIndex />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
