/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import SimpleBottomNavigation from '../../../Components/Layouts/SimpleBottomNavigation'
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import Header from '../../../Components/Layouts/Header'
import FullPageLoader from '../../../Components/Loaders/FullPageLoader'
import Auth from '../../../Components/Authentication/Auth'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SimpleMessageModal from '../../../Components/MessageModal/SimpleMessageModal'
import { fetchDurationFees, fetchYearlyFees, resetFeesState } from '../../../Features/Fees/FeesSlice'
import CachedIcon from '@mui/icons-material/Cached';

const YearlyFeesIndex = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const currentYear = new Date().getFullYear();

    const [loader, setLoader] = useState(false)
    const [list, setList] = useState([])
    const [filteredList, setFilteredList] = useState([])
    const [toggleState, setToggleState] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [tabState, setTabState] = useState('yearly')
    const [isFeesShow, setIsFeesShow] = useState(false)
    const [formattedStartDate, setFormattedStartDate] = useState('');
    const [formattedEndDate, setFormattedEndDate] = useState('');
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [data, setData] = useState({
        startDate: '',
        endDate: '',
    })

    const years = Array.from({ length: 3000 - 1900 + 1 }, (v, k) => k + 1900);

    const { feeses, responseStatus, responseMessage } = useSelector(state => state.feeses)

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
        });
    };

    const restorePage = () => {
        setLoader(true)
        setSelectedYear(currentYear)
        setData({
            startDate: '',
            endDate: '',
        })
        dispatch(resetFeesState())
        setIsFeesShow(false)
        setTimeout(() => {
            setLoader(false)
        }, 1000)
    }

    useEffect(() => {
        if (data?.startDate && data?.endDate) {
            setFormattedStartDate(new Date(data?.startDate).toLocaleDateString('en-US', {
                day: '2-digit',
                month: 'long',
                year: 'numeric'
            }));
            setFormattedEndDate(new Date(data?.endDate).toLocaleDateString('en-US', {
                day: '2-digit',
                month: 'long',
                year: 'numeric'
            }));
        }
      }, [feeses]);

    const handleChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const handleYearlySubmit = (e) => {
        e.preventDefault()
        setList([])
        setLoader(true)
        dispatch(fetchYearlyFees({ year: selectedYear }))
    };

    const handleDateRangeSubmit = (e) => {
        e.preventDefault()
        setList([])
        setLoader(true)
        dispatch(fetchDurationFees(data))
    };

    useEffect(()=>{
        if(responseStatus == 'success' && responseMessage == 'Get All Yearly Fees'){
            setList(feeses?.data)
            setIsFeesShow(true)
            setTimeout(() => {
                setLoader(false)
            }, 500)
        }
        if(responseStatus == 'success' && responseMessage == 'Get All Fees of the Duration'){
            setList(feeses?.data)
            setIsFeesShow(true)
            setTimeout(() => {
                setLoader(false)
            }, 500)
        }
        if(responseStatus == 'rejected' && responseMessage != '' && responseMessage != null){
            setLoader(false)
            setToggleState(true)
        }
    },[feeses, responseStatus, responseMessage])

    useEffect(() => {
        const filtered = list.filter(val =>
            val.studentName.toLowerCase().includes(searchTerm.toLowerCase())
        )
        setFilteredList(filtered)
    }, [searchTerm, list])

    return (
        <>
            <Auth />
            {loader && <FullPageLoader />}
            <Header pageName="Yearly Fees" shouldBack={true} />
            {
                !isFeesShow ?
                    <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '90vh' }}>
                        <Box sx={{ minWidth: '300px' }}>
                            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', mb: 2 }}>
                                <Button variant={tabState == 'yearly' ? 'contained': 'outlined'} sx={{ width: '50%', borderRadius: 0, background: tabState != 'yearly' && '#fff' }} color='success' onClick={()=>setTabState('yearly')}>Yearly</Button>
                                <Button variant={tabState == 'date' ? 'contained': 'outlined'} sx={{ width: '50%', borderRadius: 0, background: tabState != 'date' && '#fff' }} color='success' onClick={()=>setTabState('date')}>Date Range</Button>
                            </Box>
                            {
                                tabState == 'yearly' ?
                                    <form onSubmit={handleYearlySubmit}>
                                        <FormControl size='small' sx={{ background: '#fff' }} fullWidth>
                                            <InputLabel id="year-select-label">Year</InputLabel>
                                            <Select
                                                labelId="year-select-label"
                                                id="year-select"
                                                value={selectedYear}
                                                label="Year"
                                                onChange={handleChange}
                                            >
                                                {years.map(year => (
                                                    <MenuItem key={year} value={year}>
                                                        {year}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <Button type='submit' size='small' sx={{ mt: 2 }} variant="contained" color='success' fullWidth>Search</Button>
                                    </form>
                                : ''
                            }
                            {
                                tabState == 'date' ?
                                    <form onSubmit={handleDateRangeSubmit}>
                                        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                                            <Box sx={{ fontSize: '13px', width: '100px' }}>From Date:</Box>
                                            <TextField 
                                                type='date'
                                                id="startDate"
                                                name="startDate"
                                                sx={{ background: '#fff' }}
                                                value={data?.startDate}
                                                onChange={(e)=>setData({ ...data, startDate: e.target.value })}
                                                variant="outlined"
                                                size='small'
                                                fullWidth 
                                            />
                                        </Box>
                                        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', gap: 2 }}>
                                            <Box sx={{ fontSize: '13px', width: '100px' }}>Till Date:</Box>
                                            <TextField 
                                                type='date'
                                                id="endDate"
                                                name="endDate"
                                                sx={{ background: '#fff' }}
                                                value={data?.endDate}
                                                onChange={(e)=>setData({ ...data, endDate: e.target.value })}
                                                variant="outlined"
                                                size='small'
                                                fullWidth 
                                            />
                                        </Box>
                                        <Button type='submit' size='small' sx={{ mt: 2 }} variant="contained" color='success' fullWidth>Search</Button>
                                    </form>
                                : ''
                            }
                        </Box>
                    </Box>
                : 
                <Box sx={{ p: 2, mt: 4 }}>
                    <Box sx={{ color: 'gray' }}>
                        {
                            tabState == 'yearly' ? <Box>Year - {selectedYear}</Box> : <Box>Duration - {formattedStartDate} - {formattedEndDate}</Box>
                        }
                        <Box>Total Fees Collected - <Typography variant="body2" component="span" sx={{ color: '#2e7d32', fontWeight: 'bold' }}>₹{feeses?.totalSubmittedFeesAmount}</Typography></Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                        <Button sx={{ my: 2, float: 'right' }} variant="contained" color='success' size='small' onClick={()=>{
                            setTimeout(() => {
                                restorePage()
                            }, 400)
                        }}><CachedIcon fontSize='small' /></Button>
                        <TextField
                            type='search' 
                            id="standard-basic" 
                            label="Search Student" 
                            variant="standard"
                            sx={{ mb: 1 }}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            fullWidth 
                        />
                    </Box>
                    {
                        Array?.isArray(filteredList) && filteredList?.length > 0 ?
                        Array?.isArray(filteredList) && filteredList?.map((val,key)=>(
                            <Button key={key} sx={{ mt: 1, background: '#fff' }} variant="outlined" color='success' onClick={()=>{
                                setTimeout(() => {
                                    navigate(`/cchs-mobile/fees-detail/${val?._id}`)
                                }, 500)
                            }} fullWidth>
                                <Box sx={{ textAlign: 'left', width: '100%' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 3 }}>
                                        <Box>
                                            <Typography variant="h6" color="primary" sx={{ fontSize: '14px', color: '#2e7d32' }}>{val?.studentName}</Typography>
                                            <Box sx={{ color: 'gray', fontSize: '12px' }}>Submitted On - {formatDate(val?.createdAt)}</Box>
                                        </Box>
                                        <Box sx={{ fontSize: '14px' }}>₹{val?.submittedFeesAmount}</Box>
                                    </Box>
                                </Box>
                            </Button>
                        ))
                        : <Box sx={{ textAlign: 'center' }}>No Record Found</Box>
                    }
                </Box>
            }
            <SimpleBottomNavigation />

            {/* Success/Error Message Modal */}
            <SimpleMessageModal toggleState={toggleState} onClick={()=>{
                // fetchData()
                setToggleState(false)
            }} title={responseStatus == 'success' ? "Success" : "Error"} message={responseMessage} />
        </>
    )
}

export default YearlyFeesIndex