/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import SimpleBottomNavigation from '../../../Components/Layouts/SimpleBottomNavigation'
import { Box, Button } from '@mui/material'
import Header from '../../../Components/Layouts/Header'
import Auth from '../../../Components/Authentication/Auth'
import LockIcon from '@mui/icons-material/Lock';
import InfoIcon from '@mui/icons-material/Info';
import Cookies from 'js-cookie';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useNavigate } from 'react-router-dom'
import FullPageLoader from '../../../Components/Loaders/FullPageLoader'
import { useDispatch } from 'react-redux'
import { resetAuthState } from '../../../Features/Auth/AuthSlice'

const ProfileIndex = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [loader, setLoader] = useState(false)

    const handleLogout = () => {
        setLoader(true)
        Cookies.remove('cchsMobileLoggedInUserToken')
        dispatch(resetAuthState())
        navigate('/')
        // setTimeout(() => {
        //     window?.location?.reload()
        // }, 500);
    }

    return (
        <>
            <Auth />
            {loader && <FullPageLoader />}
            <Header pageName="Profile" />
            <Box sx={{ p: 2, mt: 5 }}>
                <center>
                    <img src="/originalLogo.png" style={{ height: '150px' }} alt="/originalLogo.png" />
                </center>
            </Box>
            <Box sx={{ p: 0, mt: 1 }}>
                <Button variant="text" color='success' sx={{ borderBottom: '1px solid lightgray', borderTop: '1px solid lightgray', borderRadius: 0, px: 2, py: 1, background: '#fff' }} fullWidth onClick={()=>{
                    setTimeout(() => {
                        navigate('/cchs-mobile/change-password')
                    }, 400)
                }}>
                    <Box sx={{ width: '100%', textAlign: 'left', color: 'gray' }}><LockIcon fontSize='small' />&nbsp;&nbsp;&nbsp;Change Password</Box>
                </Button>
                <Button variant="text" color='success' sx={{ borderBottom: '1px solid lightgray', borderRadius: 0, px: 2, py: 1, background: '#fff' }} fullWidth>
                    <Box sx={{ width: '100%', textAlign: 'left', color: 'gray' }}><InfoIcon fontSize='small' />&nbsp;&nbsp;&nbsp;About App v1.0.0</Box>
                </Button>
                <Button variant="text" color='success' sx={{ borderBottom: '1px solid lightgray', borderRadius: 0, px: 2, py: 1, background: '#fff' }} fullWidth onClick={()=>{
                    setTimeout(() => {
                        handleLogout()
                    }, 400)
                }}>
                    <Box sx={{ width: '100%', textAlign: 'left', color: 'gray' }}><PowerSettingsNewIcon fontSize='small' />&nbsp;&nbsp;&nbsp;Sign out</Box>
                </Button>
            </Box>
            <SimpleBottomNavigation />
        </>
    )
}

export default ProfileIndex