/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Box, Button, IconButton, InputAdornment, Paper, TextField, Tooltip, Typography } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import FullPageLoader from '../../Components/Loaders/FullPageLoader';
import { useNavigate } from 'react-router-dom';
import SimpleMessageModal from '../../Components/MessageModal/SimpleMessageModal';
import GuestAuth from '../../Components/Authentication/GuestAuth';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { createToken, resetForgetPasswordState, resetPassword, verifyToken } from '../../Features/ForgetPassword/ForgetPasswordSlice';

const ForgetPassword = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const fpFields = {
        whatsAppNumber: '',
    }
    
    const vtFields = {
        whatsAppNumber: '',
        token: '',
    }
    
    const npFields = {
        password: '',
        confirmPassword: '',
    }

    const { forgetPasswords, responseStatus, responseMessage } = useSelector(state => state.forgetPasswords)

    const [loader, setLoader] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [toggleState, setToggleState] = useState(false);
    const [formState, setFormState] = useState('fp')
    const [fpData, setFpData] = useState(fpFields)
    const [vtData, setVtData] = useState(vtFields)
    const [npData, setNpData] = useState(npFields)

    const handleFpInput = (e) => {
        const { name, value } = e.target

        setFpData({
            ...fpData,
            [name]: value
        })
    }

    const handleVtInput = (e) => {
        const { name, value } = e.target

        setVtData({
            ...vtData,
            [name]: value
        })
    }

    const handleNpInput = (e) => {
        const { name, value } = e.target

        setNpData({
            ...npData,
            [name]: value
        })
    }

    const handleFpSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(createToken(fpData))
    }

    const handleVtSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(verifyToken(vtData))
    }

    const handleNpSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(resetPassword(npData))
    }

    useEffect(()=>{
        if(responseStatus == 'success' && responseMessage == 'OTP sent to your WhatsApp Successfully'){
            setLoader(false)
            setFormState('vt')
            setToggleState(true)
        }
        if(responseStatus == 'success' && responseMessage == 'OTP Verified Successfully'){
            setLoader(false)
            setFormState('np')
            setToggleState(true)
        }
        if(responseStatus == 'success' && responseMessage == 'Password Changed Successfully, Please Login'){
            setFpData({
                whatsAppNumber: '',
            })
            setVtData({
                whatsAppNumber: '',
                token: '',
            })
            setNpData({
                whatsAppNumber: '',
                password: '',
                confirmPassword: '',
            })
            setLoader(false)
            setFormState('fp')
            setToggleState(true)
        }
        if(responseStatus == 'rejected' && responseMessage != '' && responseMessage != null){
            setLoader(false)
            setToggleState(true)
        }
    },[forgetPasswords, responseStatus, responseMessage])

    return (
        <>
            <GuestAuth />
            {loader && <FullPageLoader />}
            <Paper 
                elevation={3}
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    borderRadius: 0,
                    zIndex: 1100,
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, p: 1 }}>
                        <Tooltip title="Back" onClick={()=>navigate(-1)}>
                            <IconButton sx={{ p: 0 }}>
                                <ArrowBackIcon fontSize='small' color='success' />
                            </IconButton>
                        </Tooltip>
                        <Typography component="h4">{formState == 'fp' ? 'Forget Password' : formState == 'vt' ? 'Verify OTP' : formState == 'np' ? 'Change Password' : ''}</Typography>
                    </Box>
                </Box>
            </Paper>
            <Box sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ px: 2 }}>
                    <center><img src="/originalLogo.png" style={{ height: '120px' }} alt="/originalLogo.png" /></center>
                    <Typography variant='h5' sx={{ textAlign: 'center', fontWeight: 'bold', mb: 2, color: '#2e7d32' }} color="primary">CCHS Mobile</Typography>
                    {
                        formState == 'fp' ?
                            <form onSubmit={handleFpSubmit}>
                                <TextField 
                                    id="whatsAppNumber" 
                                    label="WhatsApp Number" 
                                    variant="outlined" 
                                    size='small'
                                    sx={{ mb: 2, background: '#fff' }}
                                    name='whatsAppNumber'
                                    value={fpData?.whatsAppNumber}
                                    onChange={handleFpInput}
                                    fullWidth
                                    required
                                />
                                <Button type='submit' size='small' variant="contained" color='success' fullWidth>Send OTP</Button>
                            </form>
                        : formState == 'vt' ?
                            <form onSubmit={handleVtSubmit}>
                                <TextField 
                                    id="token" 
                                    label="Token" 
                                    variant="outlined" 
                                    size='small'
                                    sx={{ mb: 2, background: '#fff' }}
                                    name='token'
                                    value={vtData?.token}
                                    onChange={handleVtInput}
                                    fullWidth
                                    required
                                />
                                <Button type='submit' size='small' variant="contained" color='success' fullWidth>Verify</Button>
                            </form>
                        : formState == 'np' ?
                            <form onSubmit={handleNpSubmit}>
                                <TextField 
                                    type={showPassword ? 'text' : 'password'}
                                    id="password" 
                                    label="Password" 
                                    variant="outlined" 
                                    size='small'
                                    sx={{ mb: 2, background: '#fff' }}
                                    name='password'
                                    value={npData?.password}
                                    onChange={handleNpInput}
                                    fullWidth
                                    required
                                />
                                <TextField
                                    type={showPassword ? 'text' : 'password'}
                                    id="confirmPassword" 
                                    label="Confirm Password" 
                                    variant="outlined" 
                                    size='small'
                                    sx={{ mb: 2, background: '#fff' }}
                                    name='confirmPassword'
                                    value={npData?.confirmPassword}
                                    onChange={handleNpInput}
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={()=>setShowPassword(!showPassword)}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff color='success' /> : <Visibility color='success' />}
                                                </IconButton>
                                        </InputAdornment>
                                        ),
                                    }}
                                    fullWidth
                                    required
                                />
                                <Button type='submit' size='small' variant="contained" color='success' fullWidth>Save</Button>
                            </form>
                        : ''
                    }
                </Box>
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        width: '100%',
                        zIndex: 9999,
                        borderTop: '1px solid #2e7d32',
                        fontSize: '10px',
                        p: 1,
                        textAlign: 'center',
                        background: '#fff'
                    }}
                >Powered by City Children High School, &copy; All Rights Reserved.</Box>
            </Box>

            {/* Success/Error Message Modal */}
            <SimpleMessageModal toggleState={toggleState} onClick={()=>{
                dispatch(resetForgetPasswordState())
                setToggleState(false)
            }} title="Error" message={responseMessage} />
        </>
    )
}

export default ForgetPassword