/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import Header from '../../../Components/Layouts/Header'
import SimpleBottomNavigation from '../../../Components/Layouts/SimpleBottomNavigation'
import { Box, Button, IconButton, InputAdornment, TextField } from '@mui/material'
import Auth from '../../../Components/Authentication/Auth'
import { useDispatch, useSelector } from 'react-redux'
import SimpleMessageModal from '../../../Components/MessageModal/SimpleMessageModal'
import FullPageLoader from '../../../Components/Loaders/FullPageLoader'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { resetForgetPasswordState, resetPassword } from '../../../Features/ForgetPassword/ForgetPasswordSlice'

const ChangePassword = () => {

    const dispatch = useDispatch()

    const fields = {
        whatsAppNumber: '',
        password: '',
        confirmPassword: '',
    }

    const [loader, setLoader] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [data, setData] = useState(fields)
    const [toggleState, setToggleState] = useState(false)

    const { forgetPasswords, responseStatus, responseMessage } = useSelector(state => state.forgetPasswords)

    const handleInput = (e) => {
        const { name, value } = e.target

        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(resetPassword(data))
    }

    useEffect(()=>{
        if(responseStatus == 'success' && responseMessage == 'Password Changed Successfully'){
            setData({
                whatsAppNumber: '',
                password: '',
                confirmPassword: '',
            })
            setLoader(false)
            setToggleState(true)
        }
        if(responseStatus == 'rejected' && responseMessage != '' && responseMessage != null){
            setLoader(false)
            setToggleState(true)
        }
    },[forgetPasswords, responseStatus, responseMessage])

    return (
        <>
            <Auth />
            {loader && <FullPageLoader />}
            <Header pageName="Change Password" shouldBack={true} />
            <Box sx={{ p: 2, mt: 3 }}>
                <Box variant='p' sx={{ margin: '15px 0', fontSize: '12px' }}>Fields with * are mandatory</Box>
                <form onSubmit={handleSubmit}>
                    <TextField 
                        id="whatsAppNumber" 
                        label="WhatsApp Number" 
                        variant="outlined" 
                        size='small'
                        sx={{ mb: 2, background: '#fff' }}
                        name='whatsAppNumber'
                        value={data?.whatsAppNumber}
                        onChange={handleInput}
                        fullWidth
                        required
                    />
                    <TextField 
                        type={showPassword ? 'text' : 'password'}
                        id="password" 
                        label="Password" 
                        variant="outlined" 
                        size='small'
                        sx={{ mb: 2, background: '#fff' }}
                        name='password'
                        value={data?.password}
                        onChange={handleInput}
                        fullWidth
                        required
                    />
                    <TextField
                        type={showPassword ? 'text' : 'password'}
                        id="confirmPassword" 
                        label="Confirm Password" 
                        variant="outlined" 
                        size='small'
                        sx={{ mb: 2, background: '#fff' }}
                        name='confirmPassword'
                        value={data?.confirmPassword}
                        onChange={handleInput}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=>setShowPassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff color='success' /> : <Visibility color='success' />}
                                    </IconButton>
                            </InputAdornment>
                            ),
                        }}
                        fullWidth
                        required
                    />
                    <Button type='submit' size='small' variant="contained" color='success' fullWidth>Save</Button>
                </form>
            </Box>
            <SimpleBottomNavigation />

            {/* Success/Error Message Modal */}
            <SimpleMessageModal toggleState={toggleState} onClick={()=>{
                dispatch(resetForgetPasswordState())
                setToggleState(false)
            }} title={responseStatus == 'success' ? "Success" : "Error"} message={responseMessage} />
        </>
    )
}

export default ChangePassword