/* eslint-disable eqeqeq */
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CancelIcon from '@mui/icons-material/Cancel';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 310,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 0,
};

export default function SimpleMessageModal({ toggleState, title, message, onClick }) {
    return (
        <div>
            <Modal
                open={toggleState}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ textAlign: 'right', p: 2 }}>
                        <CancelIcon color='error' onClick={onClick} />
                    </Box>
                    <Box sx={{ px: 4, pb: 4 }}>
                        <Box sx={{ textAlign: 'center' }}>
                            {
                                title == 'Success' ?
                                <DoneAllIcon fontSize='large' color='success' />
                                :
                                <ReportProblemIcon fontSize='large' color='error' />
                            }
                        </Box>
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
                            {title}
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ textAlign: 'center' }}>
                            {message}
                        </Typography>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
