/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import Header from '../../../Components/Layouts/Header'
import SimpleBottomNavigation from '../../../Components/Layouts/SimpleBottomNavigation'
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import Auth from '../../../Components/Authentication/Auth'
import { useDispatch, useSelector } from 'react-redux'
import { createFees, resetFeesState } from '../../../Features/Fees/FeesSlice'
import SimpleMessageModal from '../../../Components/MessageModal/SimpleMessageModal'
import FullPageLoader from '../../../Components/Loaders/FullPageLoader'

const AddFees = () => {

    const dispatch = useDispatch()

    const fields = {
        studentName: '',
        studentClass: '',
        payeeName: '',
        payeeMailId: '',
        payeeWhatsAppNumber: '',
        submittedFeesAmount: '',
        pendingFeesAmount: '',
        paymentMode: '',
        note: '',
    }

    const [loader, setLoader] = useState(false)
    const [data, setData] = useState(fields)
    const [toggleState, setToggleState] = useState(false)

    const { feeses, responseStatus, responseMessage } = useSelector(state => state.feeses)

    const handleInput = (e) => {
        const { name, value } = e.target

        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(createFees(data))
    }

    useEffect(()=>{
        if(responseStatus == 'success' && responseMessage == 'Fees Details Added Successfully'){
            setData({
                studentName: '',
                studentClass: '',
                payeeName: '',
                payeeMailId: '',
                payeeWhatsAppNumber: '',
                submittedFeesAmount: '',
                pendingFeesAmount: '',
                paymentMode: '',
                note: '',
            })
            setLoader(false)
            setToggleState(true)
        }
        if(responseStatus == 'rejected' && responseMessage != '' && responseMessage != null){
            setLoader(false)
            setToggleState(true)
        }
    },[feeses, responseStatus, responseMessage])

    return (
        <>
            <Auth />
            {loader && <FullPageLoader />}
            <Header pageName="Add Fees" />
            <Box sx={{ p: 2, mt: 3 }}>
                <Box variant='p' sx={{ margin: '15px 0', fontSize: '12px' }}>Fields with * are mandatory</Box>
                <form onSubmit={handleSubmit}>
                    <TextField 
                        id="studentName" 
                        label="Student Name" 
                        variant="outlined" 
                        size='small'
                        sx={{ mb: 2, background: '#fff' }}
                        name='studentName'
                        value={data?.studentName}
                        onChange={handleInput}
                        fullWidth
                        required
                    />
                    <TextField 
                        id="studentClass" 
                        label="Student Class" 
                        variant="outlined" 
                        size='small'
                        sx={{ mb: 2, background: '#fff' }}
                        name='studentClass'
                        value={data?.studentClass}
                        onChange={handleInput}
                        fullWidth
                        required
                    />
                    <TextField 
                        id="payeeName" 
                        label="Payee Name" 
                        variant="outlined" 
                        size='small'
                        sx={{ mb: 2, background: '#fff' }}
                        name='payeeName'
                        value={data?.payeeName}
                        onChange={handleInput}
                        fullWidth
                        required
                    />
                    <TextField 
                        id="payeeMailId" 
                        label="Payee Mail ID (optional)" 
                        variant="outlined" 
                        size='small'
                        sx={{ mb: 2, background: '#fff' }}
                        name='payeeMailId'
                        value={data?.payeeMailId}
                        onChange={handleInput}
                        fullWidth
                    />
                    <TextField 
                        id="payeeWhatsAppNumber" 
                        label="Payee WhatsApp Number (optional)" 
                        variant="outlined" 
                        size='small'
                        sx={{ mb: 2, background: '#fff' }}
                        name='payeeWhatsAppNumber'
                        value={data?.payeeWhatsAppNumber}
                        onChange={handleInput}
                        fullWidth
                    />
                    <TextField
                        type='number' 
                        id="submittedFeesAmount" 
                        label="Submitted Fees Amount" 
                        variant="outlined" 
                        size='small'
                        sx={{ mb: 2, background: '#fff' }}
                        name='submittedFeesAmount'
                        value={data?.submittedFeesAmount}
                        onChange={handleInput}
                        fullWidth
                        required
                    />
                    <TextField
                        type='number' 
                        id="pendingFeesAmount" 
                        label="Pending Fees Amount" 
                        variant="outlined" 
                        size='small'
                        sx={{ mb: 2, background: '#fff' }}
                        name='pendingFeesAmount'
                        value={data?.pendingFeesAmount}
                        onChange={handleInput}
                        fullWidth
                        required
                    />
                    <FormControl sx={{ minWidth: 120, mb: 2, background: '#fff' }} size="small" fullWidth required>
                        <InputLabel id="demo-select-small-label">Payment Mode</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            name='paymentMode'
                            value={data?.paymentMode}
                            onChange={handleInput}
                            label="Payment Mode"
                        >
                            <MenuItem value="Cash">Cash</MenuItem>
                            <MenuItem value="Online">Online</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        id="outlined-multiline-flexible"
                        label="Comment/Note (optional)"
                        size='small'
                        sx={{ mb: 2, background: '#fff' }}
                        name='note'
                        value={data?.note}
                        onChange={handleInput}
                        fullWidth
                        multiline
                        maxRows={10}
                    />
                    <Button type='submit' size='small' variant="contained" color='success' fullWidth>Save</Button>
                </form>
            </Box>
            <SimpleBottomNavigation />

            {/* Success/Error Message Modal */}
            <SimpleMessageModal toggleState={toggleState} onClick={()=>{
                dispatch(resetFeesState())
                setToggleState(false)
            }} title={responseStatus == 'success' ? "Success" : "Error"} message={responseMessage} />
        </>
    )
}

export default AddFees