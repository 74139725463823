/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import SimpleBottomNavigation from '../../../Components/Layouts/SimpleBottomNavigation'
import { Box, Button, TextField, Typography } from '@mui/material'
import Header from '../../../Components/Layouts/Header'
import FullPageLoader from '../../../Components/Loaders/FullPageLoader'
import Auth from '../../../Components/Authentication/Auth'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SimpleMessageModal from '../../../Components/MessageModal/SimpleMessageModal'
import { getFeeses } from '../../../Features/Fees/FeesSlice'

const FeesList = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [loader, setLoader] = useState(false)
    const [list, setList] = useState([])
    const [filteredList, setFilteredList] = useState([])
    const [toggleState, setToggleState] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')

    const { feeses, responseStatus, responseMessage } = useSelector(state => state.feeses)

    const fetchData = () => {
        setLoader(true)
        dispatch(getFeeses())
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
        });
    };

    useEffect(()=>{
        fetchData()
    },[])

    useEffect(()=>{
        if(responseStatus == 'success' && responseMessage == 'Get All'){
            setList(feeses?.data)
            setTimeout(() => {
                setLoader(false)
            }, 500)
        }
        if(responseStatus == 'rejected' && responseMessage != '' && responseMessage != null){
            setLoader(false)
            setToggleState(true)
        }
    },[feeses, responseStatus, responseMessage])

    useEffect(() => {
        const filtered = list?.filter(val =>
            val?.studentName.toLowerCase().includes(searchTerm.toLowerCase())
        )
        setFilteredList(filtered)
    }, [searchTerm, list])

    return (
        <>
            <Auth />
            {loader && <FullPageLoader />}
            <Header pageName="Fees History" />
            <Box sx={{ p: 2, mt: 4 }}>
                <TextField
                    type='search' 
                    id="standard-basic" 
                    label="Search Student" 
                    variant="standard"
                    sx={{ mb: 1 }}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    fullWidth 
                />
                {
                    Array?.isArray(filteredList) && filteredList?.length > 0 ?
                    Array?.isArray(filteredList) && filteredList?.map((val,key)=>(
                        <Button key={key} sx={{ mt: 1, background: '#fff' }} variant="outlined" color='success' onClick={()=>{
                            setTimeout(() => {
                                navigate(`/cchs-mobile/fees-detail/${val?._id}`)
                            }, 500)
                        }} fullWidth>
                            <Box sx={{ textAlign: 'left', width: '100%' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 3 }}>
                                    <Box>
                                        <Typography variant="h6" color="primary" sx={{ fontSize: '14px', color: '#2e7d32' }}>{val?.studentName}</Typography>
                                        <Box sx={{ color: 'gray', fontSize: '12px' }}>Submitted On - {formatDate(val?.createdAt)}</Box>
                                    </Box>
                                    <Box sx={{ fontSize: '14px' }}>₹{val?.submittedFeesAmount}</Box>
                                </Box>
                            </Box>
                        </Button>
                    ))
                    : <Box sx={{ textAlign: 'center' }}>No Record Found</Box>
                }
            </Box>
            <SimpleBottomNavigation />

            {/* Success/Error Message Modal */}
            <SimpleMessageModal toggleState={toggleState} onClick={()=>{
                fetchData()
                setToggleState(false)
            }} title={responseStatus == 'success' ? "Success" : "Error"} message={responseMessage} />
        </>
    )
}

export default FeesList