/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import FullPageLoader from '../../Components/Loaders/FullPageLoader';
import { resetAuthState, userLogin } from '../../Features/Auth/AuthSlice';
import { useNavigate } from 'react-router-dom';
import SimpleMessageModal from '../../Components/MessageModal/SimpleMessageModal';
import GuestAuth from '../../Components/Authentication/GuestAuth';

const Login = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const fields = {
        email: '',
        password: ''
    }

    const { auth, loading, success, message, error } = useSelector(state => state.auth)

    const [loader, setLoader] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [toggleState, setToggleState] = useState(false);
    const [data, setData] = useState(fields)

    const handleInput = (e) => {
        const { name, value } = e.target

        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(userLogin(data))
    }

    useEffect(()=>{
        if (success && message == 'Logged In') {
            setData({
                email: '',
                password: '',
            })
            setTimeout(() => {
                setLoader(false)
                navigate('/cchs-mobile/home')
            }, 1000);
        }
        if (!success && message != '' && message != null) {
            setData({
                email: '',
                password: '',
            })
            setLoader(false)
            setToggleState(true)
        }
    },[auth, loading, success, message, error])

    return (
        <>
            <GuestAuth />
            {loader && <FullPageLoader />}
            <Box sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ px: 2 }}>
                    <center><img src="/originalLogo.png" style={{ height: '120px' }} alt="/originalLogo.png" /></center>
                    <Typography variant='h5' sx={{ textAlign: 'center', fontWeight: 'bold', mb: 2, color: '#2e7d32' }} color="primary">CCHS Mobile</Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField 
                            id="email" 
                            label="Email" 
                            variant="outlined" 
                            size='small'
                            sx={{ mb: 2, background: '#fff' }}
                            name='email'
                            value={data?.email}
                            onChange={handleInput}
                            fullWidth
                            required
                        />
                        <TextField
                            type={showPassword ? 'text' : 'password'}
                            id="password" 
                            label="Password" 
                            variant="outlined" 
                            size='small'
                            sx={{ mb: 2, background: '#fff' }}
                            name='password'
                            value={data?.password}
                            onChange={handleInput}
                            InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={()=>setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff color='success' /> : <Visibility color='success' />}
                                        </IconButton>
                                  </InputAdornment>
                                ),
                            }}
                            fullWidth
                            required
                        />
                        <Button type='submit' size='small' variant="contained" color='success' fullWidth>Login</Button>
                    </form>
                    <Button variant="text" sx={{ mt: 2, color: '#2e7d32', float: 'right' }} onClick={()=>{
                        setTimeout(() => {
                            navigate('/cchs-mobile/forget-password')
                        }, 500)
                    }}>Forget Password ?</Button>
                </Box>
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        width: '100%',
                        zIndex: 9999,
                        borderTop: '1px solid #2e7d32',
                        fontSize: '10px',
                        p: 1,
                        textAlign: 'center',
                        background: '#fff'
                    }}
                >Powered by City Children High School, &copy; All Rights Reserved.</Box>
            </Box>

            {/* Success/Error Message Modal */}
            <SimpleMessageModal toggleState={toggleState} onClick={()=>{
                dispatch(resetAuthState())
                setToggleState(false)
            }} title="Error" message={message} />
        </>
    )
}

export default Login