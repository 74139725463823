/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const baseURL = process.env.REACT_APP_BACKEND_URL;

const initialState = {
    forgetPasswords: [],
    responseStatus: "",
    responseMessage: "",
};

export const createToken = createAsyncThunk(
    "forgetPasswords/createToken",
    async (forgetPasswordData, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${baseURL}/send-otp`, forgetPasswordData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.message);
        }
    }
);

export const verifyToken = createAsyncThunk(
    "forgetPasswords/verifyToken",
    async (forgetPasswordData, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${baseURL}/verify-otp`, forgetPasswordData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.message);
        }
    }
);

export const resetPassword = createAsyncThunk(
    "forgetPasswords/resetPassword",
    async (forgetPasswordData, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${baseURL}/change-password`, forgetPasswordData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.message);
        }
    }
);


const forgetPasswordsSlice = createSlice({
    name: "forgetPasswords",
    initialState,
    reducers: {
        resetForgetPasswordState: (state) => initialState,
    },
    extraReducers: (builder) => {
        // create token
        builder
        .addCase(createToken.pending, (state) => {
            state.responseStatus = "pending";
        })
        .addCase(createToken.fulfilled, (state) => {
            state.responseStatus = "success";
            state.responseMessage = "OTP sent to your WhatsApp Successfully";
        })
        .addCase(createToken.rejected, (state, action) => {
            state.responseStatus = "rejected";
            state.responseMessage = action?.error?.message;
        });
      
        // verify token
        builder
        .addCase(verifyToken.pending, (state) => {
            state.responseStatus = "pending";
        })
        .addCase(verifyToken.fulfilled, (state) => {
            state.responseStatus = "success";
            state.responseMessage = "OTP Verified Successfully";
        })
        .addCase(verifyToken.rejected, (state, action) => {
            state.responseStatus = "rejected";
            state.responseMessage = action?.error?.message;
        });
      
        // reset password
        builder
        .addCase(resetPassword.pending, (state) => {
            state.responseStatus = "pending";
        })
        .addCase(resetPassword.fulfilled, (state) => {
            state.responseStatus = "success";
            state.responseMessage = "Password Changed Successfully";
        })
        .addCase(resetPassword.rejected, (state, action) => {
            state.responseStatus = "rejected";
            state.responseMessage = action?.error?.message;
        });
    },
});

export const { resetForgetPasswordState } = forgetPasswordsSlice.actions;
export default forgetPasswordsSlice.reducer;