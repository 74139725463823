/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import SimpleBottomNavigation from '../../../Components/Layouts/SimpleBottomNavigation'
import { Box, Button, Paper } from '@mui/material'
import Header from '../../../Components/Layouts/Header'
import FullPageLoader from '../../../Components/Loaders/FullPageLoader'
import Auth from '../../../Components/Authentication/Auth'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import SimpleMessageModal from '../../../Components/MessageModal/SimpleMessageModal'
import { getFees } from '../../../Features/Fees/FeesSlice'
import EditIcon from '@mui/icons-material/Edit';

const FeesDetail = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()

    const [loader, setLoader] = useState(false)
    const [data, setData] = useState([])
    const [toggleState, setToggleState] = useState(false)
    const [formattedDate, setFormattedDate] = useState('');

    const { feeses, responseStatus, responseMessage } = useSelector(state => state.feeses)

    const fetchData = () => {
        setLoader(true)
        dispatch(getFees(id))
    }

    useEffect(()=>{
        fetchData()
    },[])

    useEffect(() => {
        if (data?.createdAt) {
          setFormattedDate(new Date(data.createdAt).toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
          }));
        }
      }, [data]);

    useEffect(()=>{
        if(responseStatus == 'success' && responseMessage == 'Get Single'){
            setData(feeses?.data)
            setTimeout(() => {
                setLoader(false)
            }, 500)
        }
        if(responseStatus == 'rejected' && responseMessage != '' && responseMessage != null){
            setLoader(false)
            setToggleState(true)
        }
    },[feeses, responseStatus, responseMessage])

    return (
        <>
            <Auth />
            {loader && <FullPageLoader />}
            <Header pageName="Fees Detail" shouldBack={true} />
            <Box sx={{ p: 2, mt: 3 }}>
                <Paper elevation={3} sx={{ p: 2, mt: 2, borderRadius: '20px', fontSize: '14px' }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', mb: 2 }}>
                        <Box sx={{ width: '150px', color: 'gray', textTransform: 'uppercase' }}>Student Name</Box>
                        <Box>: {data?.studentName}</Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', mb: 2 }}>
                        <Box sx={{ width: '150px', color: 'gray', textTransform: 'uppercase' }}>Student Class</Box>
                        <Box>: {data?.studentClass}</Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', mb: 2 }}>
                        <Box sx={{ width: '150px', color: 'gray', textTransform: 'uppercase' }}>Payee Name</Box>
                        <Box>: {data?.payeeName}</Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', mb: 2 }}>
                        <Box sx={{ width: '150px', color: 'gray', textTransform: 'uppercase' }}>Payee Mail Id</Box>
                        <Box>: {data?.payeeMailId}</Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', mb: 2 }}>
                        <Box sx={{ width: '150px', color: 'gray', textTransform: 'uppercase' }}>Payee WhatsApp Number</Box>
                        <Box>: {data?.payeeWhatsAppNumber}</Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', mb: 2 }}>
                        <Box sx={{ width: '150px', color: 'gray', textTransform: 'uppercase' }}>Submitted Fees Amount</Box>
                        <Box>: ₹{data?.submittedFeesAmount}</Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', mb: 2 }}>
                        <Box sx={{ width: '150px', color: 'gray', textTransform: 'uppercase' }}>Submitted On</Box>
                        <Box>: {formattedDate}</Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', mb: 2 }}>
                        <Box sx={{ width: '150px', color: 'gray', textTransform: 'uppercase' }}>Pending Fees Amount</Box>
                        <Box>: ₹{data?.pendingFeesAmount}</Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                        <Box sx={{ width: '150px', color: 'gray', textTransform: 'uppercase' }}>Payment Mode</Box>
                        <Box>: {data?.paymentMode}</Box>
                    </Box>
                </Paper>
                {
                    data?.note && data?.note != '' ?
                        <Paper elevation={3} sx={{ p: 2, mt: 1, borderRadius: '20px', fontSize: '14px' }}>
                            <Box sx={{ width: '150px', color: 'gray', textTransform: 'uppercase', mb: 1 }}>Note/Comment</Box>
                            <Box>{data?.note}</Box>
                        </Paper>
                    : ''
                }
            </Box>
            <SimpleBottomNavigation />

            {/* Success/Error Message Modal */}
            <SimpleMessageModal toggleState={toggleState} onClick={()=>{
                fetchData()
                setToggleState(false)
            }} title={responseStatus == 'success' ? "Success" : "Error"} message={responseMessage} />
        </>
    )
}

export default FeesDetail