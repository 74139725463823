/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import PaidIcon from '@mui/icons-material/Paid';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HistoryIcon from '@mui/icons-material/History';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import FullPageLoader from '../Loaders/FullPageLoader';
import { useDispatch } from 'react-redux';
import { resetAuthState } from '../../Features/Auth/AuthSlice';


export default function SimpleBottomNavigation() {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const param = useParams()
  const paramId = param?.id

  const baseURL = '/cchs-mobile/'

  const [showMenuDrawer, setShowMenuDrawer] = React.useState(false)
  const [currentMenu, setCurrentMenu] = React.useState('')
  const [loader, setLoader] = React.useState(false)
  const ref = React.useRef(null);

  const handleLogout = () => {
    setLoader(true)
    Cookies.remove('cchsMobileLoggedInUserToken')
    dispatch(resetAuthState())
    navigate('/')
    // setTimeout(() => {
    //     window?.location?.reload()
    // }, 500);
  }

  React.useEffect(()=>{
    if (location?.pathname == `${baseURL}fees-list` || location?.pathname == `${baseURL}edit-fees/${paramId}` || location?.pathname == `${baseURL}fees-detail/${paramId}` || location?.pathname == `${baseURL}fees-history/${paramId}`) {
      setCurrentMenu(1)
    }
    if (location?.pathname == `${baseURL}home`) {
      setCurrentMenu(2)
    }
    if (location?.pathname == `${baseURL}add-fees`) {
      setCurrentMenu(3)
    }
    if (location?.pathname == `${baseURL}profile` || location?.pathname == `${baseURL}change-password`) {
      setCurrentMenu(4)
    }
  },[location])

  return (
    <>
      {loader && <FullPageLoader />}
      <Box sx={{ pb: 7 }} ref={ref}>
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <BottomNavigation 
            value={currentMenu} 
            showLabels
            sx={{
              '.Mui-selected': {
                color: 'success.main',
              },
              '.MuiBottomNavigationAction-root': {
                '&.Mui-selected': {
                  color: 'success.main',
                },
              },
            }}
          >
            <BottomNavigationAction label="Menu" onClick={()=>setShowMenuDrawer(true)} icon={<MenuIcon fontSize='small' />} />
            <BottomNavigationAction label="History" onClick={()=>navigate('/cchs-mobile/fees-list')} icon={<HistoryIcon fontSize='small' />} />
            <BottomNavigationAction label="Home" onClick={()=>navigate('/cchs-mobile/home')} icon={<HomeIcon fontSize='large' />} />
            <BottomNavigationAction label="Fees" onClick={()=>navigate('/cchs-mobile/add-fees')} icon={<PaidIcon fontSize='small' />} />
            <BottomNavigationAction label="Profile" onClick={()=>navigate('/cchs-mobile/profile')} icon={<AccountCircleIcon fontSize='small' />} />
          </BottomNavigation>
        </Paper>
      </Box>

      <Drawer open={showMenuDrawer} onClose={()=>setShowMenuDrawer(false)}>
        <Box sx={{ width: 250, p: 1 }} role="presentation">
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={()=>{
                setShowMenuDrawer(false)
                setTimeout(() => {
                  navigate('/cchs-mobile/fees-list')
                }, 200)
              }}>
                <ListItemIcon>
                  <HistoryIcon color='success' />
                </ListItemIcon>
                <ListItemText primary="History" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={()=>{
                setShowMenuDrawer(false)
                setTimeout(() => {
                  navigate('/cchs-mobile/yearly-fees-list')
                }, 200)
              }}>
                <ListItemIcon>
                  <DateRangeIcon color='success' />
                </ListItemIcon>
                <ListItemText primary="Yearly" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={()=>{
                setShowMenuDrawer(false)
                setTimeout(() => {
                  navigate('/cchs-mobile/message-list')
                }, 200)
              }}>
                <ListItemIcon>
                  <WhatsAppIcon color='success' />
                </ListItemIcon>
                <ListItemText primary="Messages" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={()=>{
                setShowMenuDrawer(false)
                setTimeout(() => {
                  navigate('/cchs-mobile/updates')
                }, 200)
              }}>
                <ListItemIcon>
                  <EditNoteIcon color='success' />
                </ListItemIcon>
                <ListItemText primary="Updates" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={handleLogout}>
                <ListItemIcon>
                  <PowerSettingsNewIcon color='success' />
                </ListItemIcon>
                <ListItemText primary="Sign out" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
}