import { Backdrop, Box, CircularProgress } from '@mui/material'
import React from 'react'

const FullPageLoader = () => {
    return (
        <>        
            {/* <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backdropFilter: 'blur(5px)',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    zIndex: 9999,
                }}
            >
                <CircularProgress />
            </Box> */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default FullPageLoader