/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode"

const baseURL = process.env.REACT_APP_BACKEND_URL;

const initialState = {
    feeses: [],
    responseStatus: "",
    responseMessage: "",
};

export const createFees = createAsyncThunk(
    "feeses/createFees",
    async (fees, { rejectWithValue }) => {
        try {
            const token = Cookies.get("cchsMobileLoggedInUserToken");
            const decodedData = jwtDecode(token);
            const user_id = decodedData?.id;

            const response = await axios.post(`${baseURL}/fees`, fees, {
                headers: {
                    "x-authorization": `Bearer ${user_id}`,
                    "Content-Type": "application/json"
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.message);
        }
    }
);

export const getFeeses = createAsyncThunk(
    "feeses/getFeeses",
    async () => {
        try {
            const token = Cookies.get("cchsMobileLoggedInUserToken");
            const decodedData = jwtDecode(token);
            const user_id = decodedData?.id;

            const response = await axios.get(`${baseURL}/fees`, {
                headers: {
                    "x-authorization": `Bearer ${user_id}`,
                    "Content-Type": "application/json"
                },
            });
            return response.data;
        } catch (error) {
            return error.response.data.message;
        }
    }
);

export const fetchYearlyFees = createAsyncThunk(
    "feeses/fetchYearlyFees",
    async (year, { rejectWithValue }) => {
        try {
            const token = Cookies.get("cchsMobileLoggedInUserToken");
            const decodedData = jwtDecode(token);
            const user_id = decodedData?.id;

            const response = await axios.post(`${baseURL}/yearly-fees`, year, {
                headers: {
                    "x-authorization": `Bearer ${user_id}`,
                    "Content-Type": "application/json"
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.message);
        }
    }
);

export const fetchDurationFees = createAsyncThunk(
    "feeses/fetchDurationFees",
    async (duration, { rejectWithValue }) => {
        try {
            const token = Cookies.get("cchsMobileLoggedInUserToken");
            const decodedData = jwtDecode(token);
            const user_id = decodedData?.id;

            const response = await axios.post(`${baseURL}/duration-fees`, duration, {
                headers: {
                    "x-authorization": `Bearer ${user_id}`,
                    "Content-Type": "application/json"
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.message);
        }
    }
);

export const getCurrentYearTotalFees = createAsyncThunk(
    "feeses/getCurrentYearTotalFees",
    async () => {
        try {
            const token = Cookies.get("cchsMobileLoggedInUserToken");
            const decodedData = jwtDecode(token);
            const user_id = decodedData?.id;

            const response = await axios.get(`${baseURL}/current-year-total-fees`, {
                headers: {
                    "x-authorization": `Bearer ${user_id}`,
                    "Content-Type": "application/json"
                },
            });
            return response.data;
        } catch (error) {
            return error.response.data.message;
        }
    }
);

export const getCurrentYearTotalPending = createAsyncThunk(
    "feeses/getCurrentYearTotalPending",
    async () => {
        try {
            const token = Cookies.get("cchsMobileLoggedInUserToken");
            const decodedData = jwtDecode(token);
            const user_id = decodedData?.id;

            const response = await axios.get(`${baseURL}/current-year-total-pending`, {
                headers: {
                    "x-authorization": `Bearer ${user_id}`,
                    "Content-Type": "application/json"
                },
            });
            return response.data;
        } catch (error) {
            return error.response.data.message;
        }
    }
);

export const getFees = createAsyncThunk(
    "feeses/getFees",
    async (feesId, { rejectWithValue }) => {
        try {
            const token = Cookies.get("cchsMobileLoggedInUserToken");
            const decodedData = jwtDecode(token);
            const user_id = decodedData?.id;

            const response = await axios.get(
                `${baseURL}/fees/${feesId}`, {
                headers: {
                    "x-authorization": `Bearer ${user_id}`,
                    "Content-Type": "application/json"
                },
            });
            return response.data;
        } catch (error) {
            return error.response.data.message;
        }
    }
);

export const updateFees = createAsyncThunk(
    "feeses/updateFees",
    async (fees, { rejectWithValue }) => {
        try {
            const token = Cookies.get("cchsMobileLoggedInUserToken");
            const decodedData = jwtDecode(token);
            const user_id = decodedData?.id;

            const response = await axios.put(
                `${baseURL}/fees/${fees?.id}`,
                fees,
                {
                    headers: {
                        "x-authorization": `Bearer ${user_id}`,
                        "Content-Type": "application/json"
                    },
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.message);
        }
    }
);

export const deleteFees = createAsyncThunk(
    "feeses/deleteFees",
    async (feesId, { rejectWithValue }) => {
        try {
            const token = Cookies.get("cchsMobileLoggedInUserToken");
            const decodedData = jwtDecode(token);
            const user_id = decodedData?.id;

            const response = await axios.delete(
                `${baseURL}/fees/${feesId}`, {
                headers: {
                    "x-authorization": `Bearer ${user_id}`,
                    "Content-Type": "application/json"
                },
            });
            return response.data;
        } catch (error) {
            return error.response.data.message;
        }
    }
);


const feesesSlice = createSlice({
    name: "feeses",
    initialState,
    reducers: {
        resetFeesState: (state) => initialState,
    },
    extraReducers: (builder) => {
        // create reducers
        builder
        .addCase(createFees.pending, (state) => {
            state.responseStatus = "pending";
        })
        .addCase(createFees.fulfilled, (state) => {
            state.responseStatus = "success";
            state.responseMessage = "Fees Details Added Successfully";
        })
        .addCase(createFees.rejected, (state, action) => {
            state.responseStatus = "rejected";
            state.responseMessage = action?.error?.message;
        });

        // get all reducers
        builder
        .addCase(getFeeses.pending, (state) => {
            state.responseStatus = "pending";
        })
        .addCase(getFeeses.fulfilled, (state, action) => {
            state.feeses = action.payload;
            state.responseStatus = "success";
            state.responseMessage = "Get All";
        })
        .addCase(getFeeses.rejected, (state, action) => {
            state.responseStatus = "rejected";
            state.responseMessage = action.payload;
        });

        // get all reducers
        builder
        .addCase(fetchYearlyFees.pending, (state) => {
            state.responseStatus = "pending";
        })
        .addCase(fetchYearlyFees.fulfilled, (state, action) => {
            state.feeses = action.payload;
            state.responseStatus = "success";
            state.responseMessage = "Get All Yearly Fees";
        })
        .addCase(fetchYearlyFees.rejected, (state, action) => {
            state.responseStatus = "rejected";
            state.responseMessage = action.payload;
        });

        // get all reducers
        builder
        .addCase(fetchDurationFees.pending, (state) => {
            state.responseStatus = "pending";
        })
        .addCase(fetchDurationFees.fulfilled, (state, action) => {
            state.feeses = action.payload;
            state.responseStatus = "success";
            state.responseMessage = "Get All Fees of the Duration";
        })
        .addCase(fetchDurationFees.rejected, (state, action) => {
            state.responseStatus = "rejected";
            state.responseMessage = action.payload;
        });

        // get all reducers
        builder
        .addCase(getCurrentYearTotalFees.pending, (state) => {
            state.responseStatus = "pending";
        })
        .addCase(getCurrentYearTotalFees.fulfilled, (state, action) => {
            state.feeses = action.payload;
            state.responseStatus = "success";
            state.responseMessage = "Get All Collection";
        })
        .addCase(getCurrentYearTotalFees.rejected, (state, action) => {
            state.responseStatus = "rejected";
            state.responseMessage = action.payload;
        });

        // get all reducers
        builder
        .addCase(getCurrentYearTotalPending.pending, (state) => {
            state.responseStatus = "pending";
        })
        .addCase(getCurrentYearTotalPending.fulfilled, (state, action) => {
            state.feeses = action.payload;
            state.responseStatus = "success";
            state.responseMessage = "Get All Pending";
        })
        .addCase(getCurrentYearTotalPending.rejected, (state, action) => {
            state.responseStatus = "rejected";
            state.responseMessage = action.payload;
        });

        // get reducers
        builder
        .addCase(getFees.pending, (state) => {
            state.responseStatus = "pending";
        })
        .addCase(getFees.fulfilled, (state, action) => {
            state.feeses = action.payload;
            state.responseStatus = "success";
            state.responseMessage = "Get Single";
        })
        .addCase(getFees.rejected, (state, action) => {
            state.responseStatus = "rejected";
            state.responseMessage = action.payload;
        });

        // update reducers
        builder
        .addCase(updateFees.pending, (state) => {
            state.responseStatus = "pending";
        })
        .addCase(updateFees.fulfilled, (state, action) => {
            if (Array.isArray(state.feeses)) {
            state.feeses = state.feeses.map((fees) =>
                fees.id === action.payload._id ? action.payload : fees
            );
            } else {
            state.feeses = action.payload;
            }
            state.responseStatus = "success";
            state.responseMessage = "Fees Details Updated Successfully";
        })
        .addCase(updateFees.rejected, (state, action) => {
            state.responseStatus = "rejected";
            state.responseMessage = action.payload;
        });

         // delete reducers
        builder
        .addCase(deleteFees.pending, (state) => {
            state.responseStatus = "pending";
        })
        .addCase(deleteFees.fulfilled, (state) => {
            state.responseStatus = "success";
            state.responseMessage = "Fees Record Deleted Successfully";
        })
        .addCase(deleteFees.rejected, (state, action) => {
            state.responseStatus = "rejected";
            state.responseMessage = action.payload;
        });
    },
});

export const { resetFeesState } = feesesSlice.actions;
export default feesesSlice.reducer;
